<template>
  <div class="OrderPage">
    <div class="header">
      <NavBar />
    </div>
    <div class="OperatorBody" v-if="orderById !== undefined && orderById !== null">
      <v-card-actions>
        <v-btn @click="backToOrdersClick" depressed color="#e21a1a" class="white--text mr-2 text-RussianRail"
          ><v-icon>mdi-arrow-left</v-icon>Вернуться к заказам сервиса</v-btn
        >
      </v-card-actions>
      <v-card-title class="text-h4 font-weight-bold">
        Справка № {{ orderById.orderNumber }} от {{ formatDate(orderById.createdAt) }}, {{ getReferenceTypeName(orderById.referenceType) }}
      </v-card-title>
      <v-card-title class="py-1 text-h5 font-weight-medium"> Статус: {{ getStatus(orderById) }} </v-card-title>
      <v-divider class="mt-2"></v-divider>
      <v-card-text>
        <div class="pb-3 text-h5 font-weight-medium">Общая информация</div>
        <v-row class="text-body-1">
          <v-col cols="6">
            <v-row>
              <v-col>
                <div class="text-h6 font-weight-bold">Поле</div>
                <div>ФИО:</div>
                <div>Почта:</div>
                <div>Телефон:</div>
                <div>Комментарий:</div>
              </v-col>
              <v-col>
                <div class="text-h6 font-weight-bold">Клиент</div>
                <div>{{ orderById.fullName }}</div>
                <div>{{ orderById.mail }}</div>
                <div>{{ orderById.phone }}</div>
                <div>{{ orderById.comment }}</div>
              </v-col>
              <v-col>
                <div class="text-h6 font-weight-bold">Оператор</div>
                <div>{{ orderById.modifyFullName }}</div>
                <div>{{ orderById.modifyMail }}</div>
                <div>{{ orderById.modifyPhone }}</div>
                <div>{{ orderById.modifyComment }}</div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
        <div class="pb-3 text-h5 font-weight-medium">Подробная информация</div>

        <v-row>
          <v-col cols="6">
            <v-row>
              <v-col class="text-h6 font-weight-bold">Поле</v-col>
              <v-col class="text-h6 font-weight-bold">Клиент</v-col>
              <v-col class="text-h6 font-weight-bold">Оператор</v-col>
            </v-row>
            <v-row class="text-body-1 my-0 py-0" v-for="item in getOrderFields" :key="item.id">
              <v-col v-if="item.type !== 'fullname' && item.type !== 'phone' && item.type !== 'mail' && item.type !== 'comment'" class="my-1 py-0">{{
                item.name
              }}</v-col>
              <v-col v-if="item.type !== 'fullname' && item.type !== 'phone' && item.type !== 'mail' && item.type !== 'comment'" class="my-1 py-0">{{
                orderById.reference[item.in]
              }}</v-col>
              <v-col v-if="item.type !== 'fullname' && item.type !== 'phone' && item.type !== 'mail' && item.type !== 'comment'" class="my-1 py-0">{{
                orderById.reference[item.out]
              }}</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </div>
    <div v-else>
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import NavBar from '@/components/NavBar.vue';
import ReferenceHelper from '@/helpers/reference/reference.helper';
import ViewHelper from '@/helpers/view.helper';

export default {
  data() {
    return {
      orderId: this.$route.params.orderId
    };
  },
  components: {
    NavBar
  },
  computed: {
    getOrderFields() {
      return ReferenceHelper.getFields()[this.orderById.referenceType];
    },
    ...mapGetters('admin', ['orderById'])
  },

  methods: {
    getReferenceTypeName: ReferenceHelper.getReferenceTypeName,
    formatDate: ViewHelper.formatDotDate,
    getStatus(reference) {
      return ReferenceHelper.getOrderStatus(reference.currentState);
    },
    getOrder() {
      this.fetchOrder(this.orderId);
    },
    backToOrdersClick() {
      localStorage.setItem('orderDate', this.orderById.createdAt);
      this.$router.push({ path: '/admin' });
    },
    ...mapActions('admin', ['fetchOrder'])
  },
  mounted() {
    this.getOrder();
  }
};
</script>
<style scoped>
.OperatorBody {
  font-family: RussianRail;
}
</style>
